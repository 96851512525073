<!-- =========================================================================================
    File Name: Error404.vue
    Description: 404 Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="page">
        <nav role="navigation">
            <div class="nav-background">
                <div class="img-background"></div>
            </div>
            <div class="nav-wrapper container">
                <a href="/" class="brand-logo">
                    <img src="@/assets/images/logo/cactus-big-logo1_white.png" alt="Cactus Logo" />
                </a>
                <a href="#" data-target="dropdown-lang" class="dropdown-trigger btn transparent white-text z-depth-0">
                    <i18n />
                </a>

                <div class="nav-header nav-header-gateway center">
                    <h1>CactusCard</h1>
                    <h3 class="light flow-text cactuscard-subtitle" v-html="$t('landing.subtitle')"></h3>
                </div>
            </div>
        </nav>

        <div id="content">
            <div id="contact" class="section">
                <div class="container">
                    <div class="row row-flex">
                        <div class="col s12">
                            <div class="card-panel z-depth-3">
                                <div class="row row-flex">
                                    <div class="col s12 m12">
                                        <h4 class="deep-purple-text text-darken-3 text-center mt-6">{{$t('landing.privacytitle')}}</h4>
                                        <div class="vx-col w-full md:w-1/1 lg:w-1/1 mt-12 md:mt-0 text-darken-3">
                                            <p v-html="$t('landing.privacy')"></p>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <footer class="page-footer text-center">
            <div class="container">
                <div class="row">
                    <div class="col l4 s12">
                        <a href="" class="brand-logo">
                            <img src="@/assets/images/logo/cactus-big-logo1_white.png" alt="Cactus Logo" />
                        </a>
                    </div>
                    <div class="col l8 s12">
                        <div class="row">
                            <div class="col s12 m6 text-left">
                                <h6 class="heading f-16 purple-text text-darken-3">{{$t('landing.company')}}</h6>
                                <ul>
                                    <li><a class="text-white" href="/terms">{{$t('landing.terms_label')}}</a></li>
                                    <li><a class="text-white" href="/privacy">{{$t('landing.privacy_label')}}</a></li>
                                    <li><a class="text-white" href="/mpolicy">{{$t('landing.policy_label')}}</a></li>
                                </ul>
                            </div>
                            <!--                            <div class="col s12 m3">-->
                            <!--                                <h6 class="heading f-16 purple-text text-darken-3">Products</h6>-->
                            <!--                                <ul>-->
                            <!--                                    <li><a href="<?php echo url_for('home/gateway') ?>">Gateway</a></li>-->
                            <!--                                </ul>-->
                            <!--                            </div>-->
                            <div class="col s12 m3 text-left">
                                <h6 class="heading f-16 purple-text text-darken-3">{{$t('landing.help')}}</h6>
                                <ul>
                                    <li><a class="text-white" href="/contact">{{$t('landing.contact')}}</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="footer-copyright transparent">
                <div class="container text-darken-1">
                    Copyright © 2020 Cactus Prepaid. All rights reserved.
                    <div style="margin-top:0.1rem"></div>
                    <small>
                        Guareschi y Paz SA de CV
                        Meseta 10, Apart 2LT, Benito Juarez, CP 77505, Quintana Roo, Mexico

                    </small>
                </div>
            </div>
        </footer>


    </div>
</template>
<script>

    import I18n   from '../../layouts/components/navbar/I18n.vue'
    export default {
        components: {
            I18n
        },
        data () {
            return {
                faqs: [
                    {
                        id: 1,
                        question: 'Where can I use my Visa Cactus Prepaid card?',
                        ans: 'The card can be used to make purchases at establishments affiliated with the Visa Electron acceptance mark and to\n' +
                            'withdraw funds at the ATMs that make up the Visa / Plus Network. Additionally, it is suitable for all types of Online\n' +
                            'Purchases.'
                    },
                    {
                        id: 2,
                        question: 'Who can have a Visa Cactus Prepaid card?',
                        ans: 'Any natural person. Clients from Latin America, North America, Europe and Asia are accepted.'
                    },
                    {
                        id: 2,
                        question: 'In what currency are Visa Cactus Prepaid Cards issued?',
                        ans: 'The Cards are available in USD and in EUR.'
                    },
                    {
                        id: 3,
                        question: 'Are there any limits on the amount that can be kept on the card?',
                        ans: 'Yes, the maximum amount is USD 25000 for the Card in Dollars and EUR 10000 for the Card denominated in Euros.'
                    },
                    {
                        id: 4,
                        question: 'What requirements are needed to apply for it?',
                        ans: 'Present an official identification plus Proof of Residence.'
                    },
                    {
                        id: 5,
                        question: 'What should I do when my card expires?',
                        ans: 'A new Card will be provided automatically.'
                    },
                    {
                        id: 6,
                        question: "What are my card's current fees?",
                        ans: 'See link of Fees'
                    },
                    {
                        id: 7,
                        question: "How can I top up my card balance?",
                        ans: 'Through your Company, Employer, or eventually with an International Bank Transfer to our Bank Account. If you are looking\n' +
                            'for more alternatives, do not hesitate to contact us.'
                    },
                    {
                        id: 8,
                        question: "Where can I report my card if it is lost or stolen?",
                        ans: 'Calling the numbers of the Customer Service Center that we will provide in the Welcome Letter, through your access to your\n' +
                            'online account where the Card can be temporarily or permanently suspended or also by contacting us at: info@cactusprepaid.com.'
                    },
                    {
                        id: 9,
                        question: "How can I get my replacement card if it is lost or stolen?",
                        ans: 'Contacting us at info@cactusprepaid.com or through your Employer or associated Company.'
                    },

                ],

            }},
        methods:{
            goTo(){
                window.open('http://localhost:8080/Fees.pdf', '_blank');
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "../../assets/cactus/preloader.css";
    @import "../../assets/cactus/animate.css";
    @import "../../assets/cactus/materialize.min.css";
    @import "../../assets/cactus/p.css";
</style>
